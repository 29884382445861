<template>
  <div>
    <template v-if="searchUnitsList.length > 0">
      <b-card no-body>
        <b-card-header class="py-0">
          <b-button
            size="sm"
            variant="flat-secondary"
            class="btn-icon rounded-circle ml-auto"
            style="padding: 0.5rem 10px !important"
            @click="getSelectedRow()"
          >
            <unicon name="trash-alt" width="18"></unicon>
          </b-button>
        </b-card-header>
        <b-card-body class="px-0">
          <vue-good-table
            :columns="columns"
            :rows="unitsActivePage"
            :rtl="false"
            :small="true"
            :row-style-class="rowStyleIsFree"
            styleClass="vgt-table condensed"
            ref="units-table"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'publishTime'">
                {{
                  new Date(props.row.publishTime).toISOString().substr(0, 10)
                }}
              </span>

              <span v-else-if="props.column.field === 'details'">
                <b-button
                  :to="`/subjects-units/units/details/${props.row.id}`"
                  size="sm"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  style="padding: 2px 6px !important"
                >
                  <unicon name="ellipsis-v" width="18"></unicon>
                </b-button>
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card-body>
      </b-card>
      <b-col cols="12" class="d-flex justify-content-center">
        <b-pagination
          :value="unitsFirstPage"
          :total-rows="searchUnitsList.length"
          :per-page="unitPageLength"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => unitsPagination(value)"
        >
          <template #prev-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
          <template #next-text>
            <unicon width="20" name="angle-right" fill="royalblue" />
          </template>
        </b-pagination>
      </b-col>
    </template>
    <div v-else class="text-center justify-content-center mt-2">
      <h4>لا يوجد وحدات متاحة</h4>
    </div>
    <unitDialog />
  </div>
</template>

<style>
.isFree {
  background-color: #bdffbd;
}
</style>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { mapActions, mapGetters } from "vuex";
import unitDialog from "./create-unit";
import { getUserData } from "@/auth/utils";
import { Admin } from "@/router";
import Swal from "sweetalert2";

export default {
  components: {
    unitDialog,
    VueGoodTable,
    BPagination,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
  },
  data() {
    return {
      ids: [],
      isNotSelectedRows: true,
      columns: [
        {
          label: "اسم الوحدة",
          field: "name",
          sortable: true,
        },
        {
          label: "اسم المادة",
          field: "subjectName",
          sortable: true,
        },
        {
          label: "تاريخ الإنشاء",
          field: "publishTime",
          sortable: true,
        },
        {
          label: "أستاذ الوحدة",
          field: "teacherName",
          sortable: false,
        },
        {
          label: "عدد الاشتراكات",
          field: "subscriptionsCount",
          sortable: true,
        },
        {
          label: "عدد الدروس",
          field: "lessonsCount",
          sortable: true,
        },
        {
          label: "تفاصيل",
          field: "details",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    store.commit("app/UPDATE_CREATE_BUTTON_AND_GLOBALE_SEARCH", {
      isActive: true,
      isbuttonActive: getUserData().role == Admin,
      text: "وحدة جديدة",
      fetchingFunction: this.toggleUnitDialog,
      placeHolder: "ابحث عن وحدة محددة",
      value: "",
      filterFunc: this.filterUnits,
    });
    this.fetchUnits();
    this.getSubjectsList();
    this.getTeachersList();
  },
  computed: {
    ...mapGetters([
      "unitsActivePage",
      "unitsFirstPage",
      "unitPageLength",
      "searchUnitsList",
    ]),
  },
  methods: {
    rowStyleIsFree(row) {
      return row.isFree ? "isFree" : "";
    },
    ...mapActions([
      "fetchUnits",
      "getSubjectsList",
      "getTeachersList",
      "toggleUnitDialog",
      "unitsPagination",
      "filterUnits",
      "removeUnits",
      "checkCanRemove",
    ]),
    selectionChanged() {
      this.isNotSelectedRows = !this.$refs["units-table"].selectedRows.length;
    },
    getSelectedRow() {
      this.ids = [];
      this.$refs["units-table"].selectedRows.forEach((element) => {
        this.ids.push(element.id);
      });

      //this.checkCanRemove();
      //this.removeUnits(this.ids)
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "هل أنت متأكد؟",
          text:
            "قد يؤدي حذف الوحدة لحذف حسابات مالية غير معالجة، يرجى التأكد من ذلك قبل الحذف.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "نعم!",
          cancelButtonText: "إلغاء",
          reverseButtons: false,
        })
        .then((result) => {
          if (result.value) {
            this.removeUnits(this.ids);
          }
        });
    },
  },
};
</script>
