<template>
  <div>
    <validation-observer ref="createUnit">
      <b-form>
        <b-modal
          dialog-class="unit-modal-dialog"
          content-class="unit-modal-content"
          v-model="unitModal"
          scrollable
          size="sm"
          ref="createModal"
          @hide="toggleUnitDialog(false)"
        >
          <template #modal-title>
            <strong>إضافة وحدة</strong>
          </template>
          <b-form-group label="اسم الوحدة" label-for="unitName">
            <validation-provider #default="{ errors }" name="اسم الوحدة" rules="required">
              <b-form-input
                id="unitName"
                v-model="unitForm.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="تابع للمادة" label-for="subjectId">
            <validation-provider
              #default="{ errors }"
              name="تابع للمادة"
              rules="required"
            >
              <v-select
                id="subjectId"
                v-model="unitForm.subjectId"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                :options="subjectsList"
                :reduce="(item) => item.id"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="أستاذ الوحدة" label-for="teacherId">
            <validation-provider
              #default="{ errors }"
              name="أستاذ الوحدة"
              rules="required"
            >
              <v-select
                id="teacherId"
                v-model="unitForm.teacherId"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                :options="teachersList"
                :reduce="(item) => item.id"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="ترتيب الوحدة" label-for="unitOrder">
            <validation-provider
              #default="{ errors }"
              name="ترتيب الوحدة"
              rules="required"
            >
              <b-input-group append="#">
                <b-form-input
                  id="unitOrder"
                  v-model="unitForm.unitOrder"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
           <b-form-group label="السعر الافتراضي" label-for="price">
            <validation-provider
              #default="{ errors }"
              name="السعر الافتراضي"
              rules="required"
            >
              <b-input-group append="ل.س">
                <b-form-input
                  id="price"
                  v-model="unitForm.price"
                  type="number"
                  :disabled="unitForm.isFree"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="الوصف" label-for="description">
            <validation-provider #default="{ errors }" name="الوصف" rules="required">
              <b-form-textarea
                id="description"
                v-model="unitForm.description"
                rows="4"
                :state="errors.length > 5 ? false : null"
              >
              </b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          
          <template #modal-footer>
            <b-form-checkbox 
            id="isFree"
            v-model="unitForm.isFree"
            class="custom-control-primary mr-auto" 
            name="check-button"
            switch >
            مجاني
            </b-form-checkbox>
          
            <b-button
              size="md"
              type="submit"
              variant="primary"
              @click.prevent="SubmitAddUnit()"
            >
              إضافة
            </b-button>
            <b-button
              @click="toggleUnitDialog(false)"
              size="md"
              variant="outline-primary"
            >
              تراجع
            </b-button>
          </template>
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<style>
.unit-modal-dialog {
  margin: 0 !important;
  max-height: 100vh !important;
  height: 100vh;
}
.unit-modal-content {
  max-height: 100vh !important;
  border-radius: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BInputGroup,
  BFormCheckbox
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BInputGroup,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  data: () => ({
    unitModal: false,
    required,
  }),
  created() {
    localize("ar");
  },
  computed: {
    ...mapGetters(["isUnitDialog", "unitForm", "subjectsList", "teachersList"]),
  },
  methods: {
    ...mapActions(["toggleUnitDialog", "setUnit"]),
    SubmitAddUnit() {
      this.$refs.createUnit.validate().then((success) => {
        if (success) {
          this.setUnit(this.unitForm);
          this.$refs.createModal.hide();
          this.$bvToast.toast(`تم إضافة الوحدة ${this.unitForm.name} بنجاح`, {
            title: "تم الإضافة",
            variant: "success",
            toaster: "b-toaster-bottom-left",
            solid: true,
            autoHideDelay: 2000,
            appendToast: true,
          });
        }
      });
    },
  },
  watch: {
    isUnitDialog(newVal) {
      this.unitModal = newVal;
    },
  },
};
</script>
