var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searchUnitsList.length > 0)?[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"py-0"},[_c('b-button',{staticClass:"btn-icon rounded-circle ml-auto",staticStyle:{"padding":"0.5rem 10px !important"},attrs:{"size":"sm","variant":"flat-secondary"},on:{"click":function($event){return _vm.getSelectedRow()}}},[_c('unicon',{attrs:{"name":"trash-alt","width":"18"}})],1)],1),_c('b-card-body',{staticClass:"px-0"},[_c('vue-good-table',{ref:"units-table",attrs:{"columns":_vm.columns,"rows":_vm.unitsActivePage,"rtl":false,"small":true,"row-style-class":_vm.rowStyleIsFree,"styleClass":"vgt-table condensed","select-options":{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'publishTime')?_c('span',[_vm._v(" "+_vm._s(new Date(props.row.publishTime).toISOString().substr(0, 10))+" ")]):(props.column.field === 'details')?_c('span',[_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"2px 6px !important"},attrs:{"to":("/subjects-units/units/details/" + (props.row.id)),"size":"sm","variant":"flat-secondary"}},[_c('unicon',{attrs:{"name":"ellipsis-v","width":"18"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2898335007)})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":_vm.unitsFirstPage,"total-rows":_vm.searchUnitsList.length,"per-page":_vm.unitPageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return _vm.unitsPagination(value); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('unicon',{attrs:{"width":"20","name":"angle-right","fill":"royalblue"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('unicon',{attrs:{"width":"20","name":"angle-right","fill":"royalblue"}})]},proxy:true}],null,false,1430927519)})],1)]:_c('div',{staticClass:"text-center justify-content-center mt-2"},[_c('h4',[_vm._v("لا يوجد وحدات متاحة")])]),_c('unitDialog')],2)}
var staticRenderFns = []

export { render, staticRenderFns }